
import React, {useState} from "react"
import Layout from "../../components/layout"

import WebappForm from "../../components/webapps/form"

const negosyonowAPI = require("../../../lib/requestNegosyownow")
const webappsAPI = require("../../../lib/requestWebapps");


const ENTITYID="onsbranch"


const nnpickuppickerfields = [
	{"label":"Name", "dbfield": "nnpickup_name", "type": "text", "filtertype": "textbox"},
];

const nnpickuppickerfilter = [
	{"field":"onsorderformfulfillment_id", "operation": "in", "value": "(2,3,4)"},
];

const nndropoffpickerfields = [
	{"label":"Name", "dbfield": "nndropoff_name", "type": "text", "filtertype": "textbox"},
	{"label":"Address", "dbfield": "nndropoff_strtaddress", "type": "text", "filtertype": "textbox"}
];

const nndropoffpickerfilter = [
	// N/A/Own Delivery
	//{"field":"nndropoff_id", "operation": "<>", "value": CUSTOMDELIVERYDROPOFFID}
];

const searchFields = [
	{"label":"Code", "dbfield": "onsbranch_code", "type": "text", "filtertype": "text"},
	{"label":"Name", "dbfield": "onsorganization_name", "type": "text", "filtertype": "text"},
	{"label":"Short Name", "dbfield": "onsbranch_name", "type": "text", "filtertype": "text"},
	{"label":"Active", "dbfield": "onsbranch_active", "type": "checkbox", "filtertype": "checkbox"},
	{"label":"Image", "dbfield": "onsbranch_bannerimage", "type": "image", "filtertype": "image"}
];


const formFields = [
	{"label":"Code", "field": "onsbranch_code", "value": "", "input": "textbox", "mode": "unique"},
	{"label":"Name", "field": "onsorganization_name", "value": "", "input": "picker", "mode": "required"},
	{"label":"Short Name", "field": "onsbranch_name", "value": "", "input": "textbox", "mode": "required"},
	{"label":"Active", "field": "onsbranch_active", "value": "", "input": "checkbox", "mode": "normal"},
	{"label":"Has Page", "field": "onsbranch_haspage", "value": "", "input": "hidden", "mode": "readonly"},
	{"label":"Webpage", "field": "onsbranch_url", "value": "", "input": "url", "mode": "readonly", "urlprefix":"https://negosyonow.com/organization/"},

	{"label":"Banner", "field": "onsbranch_bannerimage", "value": "", "input": "image", "mode": "normal", "imagemode":"fit", "imagewidth":1200, "imageheight":480, "imageformat":"jpg", "uploadtype": "https","filehost": "cdn.ngnw.ph", "filelocation":"images/organization/banner"},

	{"label":"Write-up", "field": "onsbranch_summary", "value": "", "input": "textarea", "mode": "normal"},

	//onsbranch_summary
];

const subformFields = [
	{
		"subformid": "onsbranchfulfillment",
		"label": "Drop off locations",
		"table": "onsbranchfulfillment",
		"sort": "nnpickup_name",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 10,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Order Type/Location",
				"field": "nnpickup_name",
				"subtotalid": "",
				"value": "",
				"input": "picker",
				"mode": "required",
				"pickerfields": nnpickuppickerfields,
				"pickerfilters": nnpickuppickerfilter
			},
			{
				"label": "Assign Drop off Location",
				"field": "nndropoff_name",
				"subtotalid": "",
				"value": "",
				"input": "picker",
				"mode": "required",
				"pickerfields": nndropoffpickerfields,
				"pickerfilters": nndropoffpickerfilter
			}
		],
		"footerlist": [
		]
	},
	{
		"subformid": "onsbranchemail",
		"label": "email Notifications",
		"table": "onsbranchemail",
		"sort": "onsbranchemail_info",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 10,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Email Address",
				"field": "onsbranchemail_info",
				"subtotalid": "",
				"value": "",
				"input": "email",
				"mode": "required"
			},
			{
				"label": "Active",
				"field": "onsbranchemail_active",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "normal"
			},
			{
				"label": "Custodian/Notes",
				"field": "onsbranchemail_notes",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal"
			}
		],
		"footerlist": [
		]
	},
	{
		"subformid": "onsbranchmobile",
		"label": "SMS Notifications",
		"table": "onsbranchmobile",
		"sort": "onsbranchmobile_info",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 10,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "11-digit # (09nnXXXXXXX)",
				"field": "onsbranchmobile_info",
				"subtotalid": "",
				"value": "",
				"input": "smsnumber",
				"mode": "required"
			},
			{
				"label": "Active",
				"field": "onsbranchmobile_active",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "normal"
			},
			{
				"label": "Custodian/Notes",
				"field": "onsbranchmobile_notes",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal",
				"info": "For selected notifications only"
			}
		],
		"footerlist": [
		]
	}
];




const SellerPage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");
	const [documentid, setDocumentid] = useState(0);


	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		var pkid = 0;
		//var customparam = {};
		if (params.hasOwnProperty("documentid")) {
			pkid = params.documentid;
		}

		//callback({"status":"Error"}); return;

		webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(response){
			if (response.status === "OK") {
				postSaveUpdates(entity, token, response.documentid, pkid === 0, function() {
					callback(response);
				});

			} else {
				callback(response);
			}
		});
	}


	function postSaveUpdates(entity, token, pkid, isnew, callback)
	{
		if (isnew) {
			negosyonowAPI.activateEntity(pkid,token, entity).then(nnactivateresponse => {
				// Activate Entity trigger build
				callback();
			});
		} else {
			negosyonowAPI.buildEntityId(pkid,token,"organization").then(nnresponse => {
				callback();
			});
		}
	}

	function customEditFieldInfo(inputlist, idx)
	{
		var tmpfield = {}
		// Add detaching/handling of parent product
		if (inputlist[idx].field === "onsbranch_url") {
			const haspage = parseInt(inputlist[idx-1].value);
			if (haspage === 1) {
				return inputlist[idx];
			}
			tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
			tmpfield.value = "Not yet available";
			tmpfield.input = "textbox";
			return tmpfield;
		} else if (documentid > 0) {
			const readonlylist = ["onsbranch_code", "onsorganization_name"];

			if (readonlylist.includes(inputlist[idx].field)) {
				tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
				tmpfield.mode = "readonly";
				return tmpfield;
			}
		}
		return inputlist[idx];
	}

	function customSetFormData(newformdata)
	{
		var newdocid = 0;
		if (newformdata.hasOwnProperty(ENTITYID+"_id")) {
			if (newformdata[ENTITYID+"_id"]) {
				newdocid = newformdata[ENTITYID+"_id"];
			}
		}

		setDocumentid(newdocid);
	}

	return <Layout location={location}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}
		>
			<WebappForm
				pagetitle={pagetitle}
				allowadd={true}
				allowdelete={false}
				entity={ENTITYID}
				mobilerowtitlefield={["onsbranch_name"]}
				searchFields={searchFields}
				editFields={[formFields]}
				editSubFormFields={subformFields}

				customSubmit={customSubmit}
				customSetFormData={customSetFormData}
				customEditFieldInfo={customEditFieldInfo}

				token={token} />
		</Layout>

}


export default SellerPage;
